.PromptsView p.prompt-info {
  margin-bottom: 8px;
}

.PromptsView .prompt-container {
  display: flex;
}

.PromptsView .prompt-container .Prompt {
  margin-right: 16px;
  width: 100%;
}

.PromptsView .prompt-container button {
  height: inherit;
}