@import '@material/react-button/index.scss';
@import '@material/react-dialog/index.scss';
@import '@material/react-icon-button/index.scss';
@import '@material/react-layout-grid/index.scss';
@import '@material/react-linear-progress/index.scss';
@import '@material/react-material-icon/index.scss';
@import '@material/react-snackbar/index.scss';
@import '@material/react-switch/index.scss';
@import '@material/react-text-field/index.scss';
@import '@material/react-typography/index.scss';
@import "@material/theme/color-palette";

// COLORS
.green {
  color: $material-color-green-700;
}
.secondary {
  color: $mdc-theme-secondary;
}

body {
  background-color: #f9f9f9;
  font-family: Roboto, sans-serif;
  margin: 0;
}

h1.landing-page-header {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 70px;
  text-align: center;
}

h1.page-header {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 50px;
}

h5 {
  margin-top: 0;
}

main {
  margin: 100px auto;
  padding: 0 30px;
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 24px;
}

p.info {
  color: $material-color-blue-grey-500;
  font-size: 0.98rem;
}

p.with-icon {
  align-items: flex-start;
  display: flex;
}

p.with-icon > i {
  margin-right: 16px;
}

.bottom-8 {
  margin-bottom: 8px;
}

.bottom-16 {
  margin-bottom: 16px;
}

.bottom-32 {
  margin-bottom: 32px;
}

.bottom-48 {
  margin-bottom: 48px !important;
}

.bottom-64 {
  margin-bottom: 64px !important;
}

.btns-inline > a, .btns-inline > button {
  margin-right: 32px;
}

.btns-inline > a:last-child, .btns-inline > button:last-child {
  margin-right: 0;
}

.centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

.error {
  color: $mdc-theme-error;
}

.form-field {
  margin-bottom: 30px;
}

.form-field .mdc-text-field, .form-field input {
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.list .row {
  align-items: center;
  display: flex;
  font-weight: 300;
  margin: 16px 0;
}

.list .row.header {
  border-bottom: 1px solid $material-color-grey-400;
  font-weight: 500;
  padding-bottom: 4px;
}

.list .row > * {
  flex: 3;
  margin-right: 16px;
}

.list .row > *:last-child {
  margin-right: 0;
}

.list .row > *.col-1 {
  flex: 1;
}

.list .row > *.col-2 {
  flex: 2;
}

.p-with-btn {
  align-items: center;
  display: flex;
}

.p-with-btn > p {
  margin-right: 16px;
}

.mdc-button.btn-filled-error {
  @include mdc-button-filled-accessible($mdc-theme-error);
}

.mdc-button.btn-filled-gray {
  @include mdc-button-filled-accessible(gray);
}

.mdc-button.btn-filled-secondary {
  @include mdc-button-filled-accessible($mdc-theme-secondary);
}

.mdc-button.btn-secondary {
  color: $mdc-theme-secondary;
}

.mdc-button.btn-secondary::before, .mdc-button.btn-secondary::after {
  background-color: $mdc-theme-secondary;
}

.mdc-dialog .mdc-dialog__content {
  color: black;
}

.num-drafts-container {
  border: 1px solid $material-color-blue-grey-400;
  border-radius: 2px;
  color: $mdc-theme-secondary;
  font-weight: bold;
  margin: 0 8px;
  padding: 4px 8px;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.right-16 {
  margin-right: 16px;
}

.standout {
  color: $mdc-theme-secondary;
  font-weight: bold;
}

.width-base {
  margin-left: auto;
  margin-right: auto;
}

.width-400 {
  @extend .width-base;
  max-width: 400px;
}

.width-700 {
  @extend .width-base;
  max-width: 700px;
}

.width-900 {
  @extend .width-base;
  max-width: 900px;
}

.width-1300 {
  @extend .width-base;
  max-width: 1300px;
}

@media only screen and (min-width: 501px) {
  .form-field {
    margin: 0 auto 30px auto;
  }

  h1.landing-page-header {
    font-size: 3.5rem;
  }

  h1.page-header {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 901px) {
  h1.landing-page-header {
    font-size: 4rem;
  }
}