@import "@material/theme/color-palette";
@import "@material/theme/mdc-theme";

.Editor {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  min-width: 500px;
}

.Editor .top-group {
  border-bottom: 1px solid $material-color-grey-400;
  display: flex;
}

.Editor .top-group p.prompt-text {
  border-right: 1px solid $material-color-grey-400;
  font-size: 0.8rem;
  line-height: 20px;
  margin: 0;
  padding: 24px;
}

.Editor .top-group .time-remaining {
  align-items: center;
  color: $mdc-theme-secondary;
  display: flex;
  font-size: 1.75rem;
  font-weight: bold;
  justify-content: center;
  min-width: 125px;
}

.Editor .middle-group {
  padding: 24px 60px 24px 48px;
}

.Editor .middle-group textarea {
  border: none;
  font-size: 0.95rem;
  height: 500px;
  line-height: 20px;
  max-height: calc(100vh - 400px);
  min-height: 200px;
  outline: none;
  padding-right: 12px;
  resize: none;
  width: 100%;
}

.Editor .bottom-group {
  display: flex;
  justify-content: space-between;
}

.Editor .bottom-group .word-count-container {
  border-top: 1px solid $material-color-grey-400;
  font-size: 1rem;
  font-weight: 300;
  padding: 16px 24px;
  width: 100%;
}

.Editor .bottom-group .word-count-container .word-count {
  color: $mdc-theme-secondary;
  font-weight: bold;
}

.Editor .bottom-group button {
  border-radius: 0 0 8px 0;
  height: auto;
  min-width: 125px;
}

@media only screen and (min-width: 901px) {
  .Editor .top-group p.prompt-text {
    font-size: 0.9rem;
  }

  .Editor .top-group .time-remaining {
    font-size: 2rem;
  }
}