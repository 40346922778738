.RewriteView .side-by-side {
  align-items: flex-start;
  display: flex;
}

.RewriteView .side-by-side > *:first-child {
  margin-right: 32px;
}

.RewriteView .side-by-side > *:last-child {
  position: sticky;
  top: 16px
}