.AppHeader {
  margin: 20px 24px;
}

.AppHeader, .AppHeader .right-side {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.AppHeader header {
  font-size: 1.5rem;
  font-weight: 700;
}

.AppHeader header a {
  color: black;
  text-decoration: none;
}

.AppHeader .dropdown {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 24px;
  top: 64px;
  width: 130px;
  z-index: 1000;
}

.AppHeader .dropdown button {
  border-radius: 0;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  width: 100%;
}

.AppHeader .dropdown button i {
  margin-right: 0;
}

.AppHeader .dropdown button span {
  width: 90px;
}

.AppHeader .dropdown a:first-child button {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.AppHeader .dropdown a:last-child button {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

@media only screen and (min-width: 600px) {
  .AppHeader header {
    font-size: 2rem;
    font-weight: 700;
  }
}