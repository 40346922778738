@import "@material/theme/color-palette";

.DraftsViewer {
  min-width: 700px;
}

.DraftsViewer .drafts-navigation {
  align-items: center;
  display: flex;
  justify-content: center;
}

.DraftsViewer .drafts-navigation .PDFViewer {
  margin: 0 32px;
}