.ShareNew .form-wrapper {
  max-width: 400px;
}

.ShareDraft .btns > * {
  margin-right: 32px;
}

.ShareDraft .btns > *:last-child {
  margin-right: 0;
}