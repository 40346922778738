@import "@material/theme/mdc-theme";

.IndexIndicators {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.IndexIndicators .dot {
  background-color: transparent;
  border: 2px solid $mdc-theme-primary;
  border-radius: 50%;
  height: 8px;
  margin: 0 4px;
  width: 8px;
}

.IndexIndicators .dot.filled {
  background-color: $mdc-theme-primary;
}