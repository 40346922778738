.AddEditPromptDialog .content {
  padding-top: 8px !important;
}

.AddEditPromptDialog .content textarea {
  min-height: 150px !important;
  min-width: 400px;
  resize: none;
}

.AddEditPromptDialog #responseTime {
  width: 100%;
}

.AddEditPromptDialog .full-width, .AddEditPromptDialog #responseTime, .AddEditPromptDialog #text {
  width: 100%;
}