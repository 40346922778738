@import "@material/theme/color-palette";

.SchedulePromptDialog .prompt-group {
  border-radius: 8px;
  cursor: pointer;
  padding: 1px 16px;
}

.SchedulePromptDialog .prompt-group:hover {
  background-color: $material-color-grey-100;
}

.SchedulePromptDialog .prompt-group:active {
  background-color: $material-color-grey-300;
}

.SchedulePromptDialog .prompt-group.selected {
  background-color: $material-color-teal-400;
  color: white;
}

.SchedulePromptDialog .prompt-group.selected .text {
  font-weight: 500;
}

.SchedulePromptDialog .prompt-group.selected .info {
  color: white;
  font-weight: 400;
}

.SchedulePromptDialog .prompt-group .text {
  font-weight: 400;
}

.SchedulePromptDialog .prompt-group .info {

}