.Prompt p.text {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 0.8rem;
  margin: 0;
  padding: 24px;
  text-align: left;
}

@media only screen and (min-width: 501px) {
  .Prompt p.text {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 901px) {
  .Prompt p.text {
    font-size: 1rem;
  }
}