.AdminView .btns {
  display: flex;
  flex-wrap: wrap;
}

.AdminView .btns button {
  height: 50px;
  margin-bottom: 16px;
  width: 100%;
}

.AdminView .btns button:last-child {
  margin-bottom: 0;
}

.AdminView .separator {
  font-weight: bold;
  margin: 0 16px;
}

@media only screen and (min-width: 501px) {
  .AdminView .btns {
    display: flex;
    flex-wrap: nowrap;
  }

  .AdminView .btns button {
    margin-bottom: 0;
    margin-right: 16px;
  }

  .AdminView .btns button:last-child {
    margin-right: 0;
  }
}