@import "@material/theme/color-palette";

.PDFViewer {
  border-radius: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: fit-content;
}

.PDFViewer nav {
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid $material-color-grey-400;
  display: flex;
  justify-content: center;
  padding: 2px 0;
}

.DraftsViewer nav > p {
  font-size: 1rem;
  margin: 0 16px;
}

.PDFViewer .page > canvas {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.PDFViewer .BlankDocument {
  background-color: white;
  border-radius: 8px;
  height: 845px;
  width: 612px;
}

.PDFViewer .BlankDocument.page {
  height: 792px;
}

.PDFViewer .BlankDocument > p {
  margin: 0;
  padding: 64px 0;
  text-align: center;
}

.PDFViewer .BlankDocument > .mdc-linear-progress {
  padding-top: 8px;
}