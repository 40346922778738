@import "@material/theme/color-palette";

.calendar {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 16px;
}

.calendar header {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 16px;
}

.calendar .loading {
  position: absolute;
  width: 350px;
}

.calendar .month .week {
  align-items: center;
  display: flex;
}

.calendar .month .week.headers {
  font-weight: 500;
}

.calendar .month .week .day {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 64px;
}

.calendar .month .week .day.disabled {
  color: $material-color-grey-400;
}

.calendar .month .week .day.today {
  background-color: $material-color-deep-purple-400;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  height: 32px;
  margin: 4px 16px;
  width: 32px;
}

.calendar .month .week .selected-wrapper {
  border: 3px solid white;
  border-radius: 8px;
  padding: 4px;
}

.calendar .month .week .selected-wrapper.selected {
  border-color: $material-color-teal-400;
}

.calendar .month .week .week-days {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.calendar .month .week .week-days.available:hover {
  background-color: $material-color-teal-50;
}

.calendar .month .week .week-days.available:active {
  background-color: $material-color-teal-100;
}

.calendar .month .week .week-days.scheduled {
  background-color: $material-color-teal-400;
  color: white;
}

.calendar .month .week .week-days.scheduled:hover {
  background-color: $material-color-teal-500;
}

.calendar .month .week .week-days.scheduled:active {
  background-color: $material-color-teal-600;
}

.SchedulingView .calendar-navigation {
  display: flex;
  justify-content: center;
}

.SchedulingView .calendar-navigation > button {
  margin-top: 180px;
}

.SchedulingView .calendar-navigation > *:first-child {
  margin-right: 16px;
}

.SchedulingView .calendar-navigation > *:last-child {
  margin-left: 16px;
}

.SchedulingView .calendar-navigation-top {
  display: none;
}

@media only screen and (max-width: 650px) {
  .calendar {
    padding: 8px;
  }

  .calendar header {
    font-size: 20px;
    margin: 8px 0;
  }

  .calendar .loading {
    width: 300px;
  }

  .calendar .month .week .day {
    font-size: 14px;
    height: 32px;
    width: 48px;
  }

  .calendar .month .week .day.today {
    height: 24px;
    margin: 2px 10px;
    width: 24px;
  }

  .SchedulingView .calendar-navigation > button {
    display: none;
  }

  .SchedulingView .calendar-navigation-top {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 8px auto;
    max-width: 366px;
  }
}